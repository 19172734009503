import React, {useState} from 'react'
import { makeStyles } from '@material-ui/styles';   
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    snackbar:{
        width: '100%',
        padding: '20px',
        backgroundColor: '#FF7777',
        borderRadius:'10px',
        fontSize:'25px',
        margin:'10px'
    },
    container:{
       width: '100%',
       height: 'calc(100vh - 120px)',
       display: 'flex',
       justifyContent: 'space-between',
       alignItems: 'center',
       flexDirection:'column'
   },
   field:{
        height:'80px',
        width:'500px',
        textAlign:'center',
        fontSize:'40px'
   },
   bottomBar:{
       width:'100%'
   },
   topBar:{
        width:'100%',
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        justifyContent:'center',
        height:'70vh'
   }
  }));

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export default function ExamChecker(props){

    const classes = useStyles()
    const [rows, setRows] = useState([]);
    const [searchKey, setKey] = useState("")
    
    
    const filter = (e)=>{
        let value = e.target.value;
        setKey(value+"")
        var today = new Date()
        
        var data = props.master.filter(row => row.initial.toLowerCase() == value.toLowerCase())
        var v = data.filter(row => row.tanggal.includes(formatDate(today)))
        setRows(v)
    }

    const clear = ()=>{
        setRows([])
    }

    var today = new Date()
    return(
        <>
            <div className={classes.container}>
                <div className={classes.topBar}>
                    <h1>Theory Exam Proctor Checker</h1>
                    <input type="text" className={classes.field} onKeyDown={(e)=>{
                        if(e.keyCode===13){
                            filter(e)
                            e.target.value = ''
                            setTimeout(()=>clear(), 5000)
                        }
                    }}/>
                </div>
                
                <div className={classes.bottomBar}>
                    {rows.map((row,key) => <div key={key} className={classes.snackbar}>
                        {row.room} | {row.course_name} | {row.start} - {row.end}
                        </div> )
                    }
                    {/* {formatDate(today)+''} */}
                    
                </div>
                
            </div>
        </>
    )
}



// 