import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import Axios from 'axios';
import XLSX from 'xlsx'

const useStyles = makeStyles(theme => ({
   container:{
       width: '100%',
       height: 'calc(100vh - 100px)',
       display: 'flex',
       justifyContent: 'center',
   }
  }));

const submitForm = e => {
    var formData = new FormData(document.getElementById('formnih'))

    Axios.post("localhost:5001/uploadExcel", formData)
    .then((res, err)=>{
        console.log(res)
    })
    .catch((res, err)=>{

    })
};

export default function InsertExamSchedule(){

    const classes = useStyles()
    const [excel, setExcel]= useState()
    
    const handleFile = (e)=>{
        const reader = new FileReader()
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e)=>{
            const bstr = e.target.result
            const wb = XLSX.read(bstr, {type: rABS?'binary':'array', bookVBA:true})

            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]

            const data = XLSX.utils.sheet_to_json(ws)
            console.log(data)

            Axios.post("https://line-siti.douglasnugroho.com/", {
                period: document.getElementById('period').value, 
                type: document.getElementById("type").value,
                data: data
            }, {
                headers: {
                  "Content-Type": "application/json",
                }
              })
            .then((res, err)=>{
                console.log(res.data)
            })
            .catch((res, err)=>{
        
            })
        }

        if(rABS){
            reader.readAsBinaryString(excel)
        }else{
            reader.readAsArrayBuffer(excel)
        }
    }

    const handleChange = (e)=>{
        const files = e.target.files
        if(files && files[0]) setExcel(files[0])
    }

    return(
        <>
            <div className={classes.container}>
                <form style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems:'center',
                    width:'800px',
                    height:'calc(100vh - 50px)' 
                }} 
                >
                    <input id='period' placeholder="Period (ex: 1910)"/>
                    <input id='type' placeholder="Type (ex: UTS)"/>
                    <input type='file' 
                    onChange={(e)=>handleChange(e)}
                     name='excel' id='filenih'/>
                    <button type='button' 
                    onClick={(e)=>handleFile(e)}
                    >UPLOAD</button>
                </form>
            </div>
        </>
    )
}
