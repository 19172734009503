import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table'
import XLSX from 'xlsx'
import Axios from 'axios';

import { TableCell, TableRow, TableHead, Table, Paper, TableContainer, TableBody, TextField } from '@material-ui/core';



const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function createData(tanggal, start, end, course_code, course_name, room, initial, period, type) {
    return { tanggal, start, end, course_code, course_name, room, initial, period, type };
  }
  
var rows = [
    createData('28/01/2020', '08:00:00', '11:30:00', 'ISYS6442', 'Applied ERP: Fundamental', '623', 'KS', '18950')
];

function loadData(row, setRow, setMaster){
    Axios.get('http://10.22.69.98:5001/getData').then((res, err)=>{
        setRow(res.data)
        setMaster(res.data)
    })
}

export default function Exams(props){

    const [fileXls, setFileXls] = useState()
    const [excel, setExcel]= useState()
    const [rows, setRow] = useState([])
    const [searchKey, setKey] = useState("")
    

    const filter = (e)=>{
        let value = e.target.value;
        setKey(value+"")
    }
    
    const classes = useStyles()

    useEffect(()=>loadData(rows, setRow, props.setMaster), [])


    return(
        <>
            <div className={classes.container}>

                <div style={{display:"flex",justifyContent:"flex-end"}}>
                    <TextField id="initial" label="Initial" onChange={(e)=>filter(e)}/>
                </div>

                <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center">Tanggal</TableCell>
                        <TableCell align="center">Start</TableCell>
                        <TableCell align="center">End</TableCell>
                        <TableCell align="center">Course Code</TableCell>
                        <TableCell align="center">Course Name</TableCell>
                        <TableCell align="center">Room</TableCell>
                        <TableCell align="center">Initial</TableCell>
                        <TableCell align="center">Period</TableCell>
                        <TableCell align="center">Type</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map(row => (row.initial.toLowerCase().includes(searchKey.toLowerCase())) && (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row" align="center">
                                {row.tanggal.split("T")[0]}
                            </TableCell>
                            <TableCell align="center">{row.start}</TableCell>
                            <TableCell align="center">{row.end}</TableCell>
                            <TableCell align="center">{row.course_code}</TableCell>
                            <TableCell align="center">{row.course_name}</TableCell>
                            <TableCell align="center">{row.room}</TableCell>
                            <TableCell align="center">{row.initial}</TableCell>
                            <TableCell align="center">{row.period}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
        </>
    )
}


