import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ExamChecker from '../components/ExamChecker';
import HomeIcon from '@material-ui/icons/Home';
import InsertExamSchedule from '../components/InsertExamSchedule';
import Exams from '../components/Exams'
import { StepIcon } from '@material-ui/core';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundImage: 'linear-gradient(to right, #063C64, #042846)',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      backgroundImage: 'linear-gradient(to right, #063C64, #042846)',
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));

export default function Home(props){
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [master, setMaster] = useState([])
    const [title, setTitle] = useState('Welcome')

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                [classes.hide]: open,
                })}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
                {title}
            </Typography>
            </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            })}
            classes={{
            paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            }),
            }}
        >
            <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            </div>
            <Divider />
            <List>
            {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
                </ListItem>
            ))} */}
                <ListItem onClick={()=>{setTitle('Welcome')}} button key={'welcome'}>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItem>
                <ListItem onClick={()=>{setTitle('Exam')}} button key={'exam'}>
                    <ListItemIcon><ImportContactsIcon/></ListItemIcon>
                    <ListItemText primary={'Exam'} />
                </ListItem>
            </List>
            {/* <Divider /> */}
            {props.manager?
            <>
              <ListItem onClick={()=>{setTitle('Insert Exam Data')}} button key={'insert exam data'}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={'Insert Exam Data'} />
              </ListItem>
                  <ListItem onClick={()=>{setTitle('Change Period Type')}} button key={'change period type'}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={'Change Period Type'} />
              </ListItem>
            </>
            :<></>}
        </Drawer>
        <main className={classes.content}>
            <div className={classes.toolbar} />
            
            <div style={title=='Welcome'?{display:'inline'}:{display:'none'}}>
                <ExamChecker setMaster={setMaster} master={master}/>
            </div>
            <div style={title=='Exam'?{display:'inline'}:{display:'none'}}>
                <Exams setMaster={setMaster} />
            </div>
            <div style={title=='Insert Exam Data'?{display:'inline'}:{display:'none'}}>
                <InsertExamSchedule setMaster={setMaster}/>
            </div>
            <div style={title=='Change Period Type'?{display:'inline'}:{display:'none'}}>
                <InsertExamSchedule setMaster={setMaster}/>
            </div>
            
        </main>
        </div>
    );
}